/*
 *
 * Helper functions for vue.js
 *
 */

// Unhide all Vues
if (typeof jQueryDifferedReady === 'undefined' || jQueryDifferedReady == null) {
    jQueryDifferedReady = [];
}
jQueryDifferedReady.push(
    function(){
        $('.vue-hide').removeClass('hide');
    }
);

// Using Animate.css for the transition classes for Vue.js
// Vue.transition('fadeUp', {
//     enterClass: 'fadeInUp',
//     leaveClass: 'fadeOutDown'
// });


// Decode query string
window.DecodeQueryString = function(q) {
    return (function(a) {
        if (a == "") return {};
        var b = {};
        for (var i = 0; i < a.length; ++i) {
            var p = a[i].split('=');
            if (p.length != 2) continue;
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
    })(q.split("&"));
};

window.getCurrentPage = function() {
    return window.DecodeQueryString(window.location.search.substring(1))['page'];
};

window.onunload = function() {
    sessionStorage.setItem("referer", window.location.href);
};

window.isRefresh = function() {
    return (window.location.href === sessionStorage.getItem("referer"));
};

window.resizeOnImageLoad = function(imageSrc) {
    var tempImage = new Image();
    tempImage.onload = function() { $(window).resize(); };
    tempImage.src = imageSrc;
};
